interface SlidingBannerProps {
  text: string;
  active: boolean;
}

const SlidingBanner: React.FC<SlidingBannerProps> = ({ text, active }) => {
  return (
    <div className="relative flex overflow-x-hidden overflow-y-hidden italic text-5xl md:text-9xl text-textAccent">
      <div
        className={`md:py-12 whitespace-nowrap ${
          active ? "animate-marquee" : ""
        }`}
      >
        <span className="mx-4">{text} -</span>
        <span className="mx-4">{text} -</span>
        <span className="mx-4">{text} -</span>
        <span className="mx-4">{text} -</span>
        <span className="mx-4">{text} -</span>
      </div>

      <div
        className={`absolute top-0 md:py-12 whitespace-nowrap ${
          active ? "animate-marquee2" : ""
        }`}
      >
        <span className="mx-4">{text} -</span>
        <span className="mx-4">{text} -</span>
        <span className="mx-4">{text} -</span>
        <span className="mx-4">{text} -</span>
        <span className="mx-4">{text} -</span>
      </div>
    </div>
  );
};

export default SlidingBanner;
