import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import LegalNotice from "./app/pages/LegalNotice";
import LandingPage from "./app/pages/LandingPage";
import CustomCursor from "./app/CustomCursor";
import Header from "./app/Header";
import Footer from "./app/Footer";
import PrivacyPolicy from "./app/pages/PrivacyPolicy";

// import reportWebVitals from "./reportWebVitals";
const router = createBrowserRouter([
  {
    path: "/",
    element: <LandingPage />,
  },
  {
    path: "/legal",
    element: <LegalNotice />,
  },
  {
    path: "/privacy",
    element: <PrivacyPolicy />,
  },
]);
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <div className="bg-bgPrimary text-textPrimary">
      <div className="hidden md:block">
        <CustomCursor />
      </div>
      <Header />
      <main>
        <RouterProvider router={router} />
      </main>
      <Footer />
    </div>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
