import { ArrowDownRightIcon } from "@heroicons/react/24/outline";
import { useEffect, useRef } from "react";

const Header = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const textRef = useRef<HTMLParagraphElement>(null);

  useEffect(() => {
    const adjustFontSize = () => {
      if (!containerRef.current || !textRef.current) return;

      const childNodes = textRef.current.childNodes;
      if (containerRef.current.clientWidth > 1024) {
        const lineHeight =
          containerRef.current.clientHeight / childNodes.length;

        let fontSize = (containerRef.current.clientWidth / 31) * 2;
        fontSize = fontSize < lineHeight ? fontSize : lineHeight;

        //loop through all textref children and change their font size
        childNodes.forEach((child) => {
          if (child instanceof HTMLElement) {
            child.style.display = `block`;
          }
        });

        textRef.current.style.fontSize = `${fontSize}px`;
        textRef.current.style.lineHeight = `${lineHeight}px`;
      } else {
        childNodes.forEach((child) => {
          if (child instanceof HTMLElement) {
            child.style.display = `inline`;
          }
        });
      }
    };

    adjustFontSize();
    window.addEventListener("resize", adjustFontSize);

    return () => {
      window.removeEventListener("resize", adjustFontSize);
    };
  });

  return (
    <div className="mt-20 py-10  px-4 md:px-8">
      <div ref={containerRef} className="h-[calc(100vh-15rem)]">
        <p
          ref={textRef}
          className="whitespace-pre-line lg:h-full font-semibold lg:font-normal text-4xl sm:text-5xl sm:leading-loose overflow-hidden"
        >
          <span>I’m Moritz Rengert, a freelance </span>
          <span>fullstack and web </span>
          <span>developer, passionate about </span>
          <span>making accessible, solid and </span>
          <span>
            usable projects{" "}
            <a href="#portfolio" aria-label="go to content">
              <ArrowDownRightIcon className="w-6 sm:w-10 md:12 inline cursor-pointer" />
            </a>
          </span>
        </p>
      </div>
    </div>
  );
};

export default Header;
