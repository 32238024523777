import React, { useEffect, useRef } from "react";

const CustomCursor: React.FC = () => {
  const cursorRef = useRef<HTMLDivElement | null>(null);
  const trailingCursorRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const cursor = cursorRef.current;
    const trailingCursor = trailingCursorRef.current;

    const updateCursor = (event: MouseEvent) => {
      if (!cursor || !trailingCursor) return;

      const x = event.clientX;
      const y = event.clientY;

      cursor.style.transform = `translate3d(${x - cursor.clientWidth / 2}px, ${
        y - cursor.clientHeight / 2
      }px, 0)`;
      trailingCursor.style.transform = `translate3d(${
        x - trailingCursor.clientWidth / 2
      }px, ${y - trailingCursor.clientHeight / 2}px, 0)`;

      const targetElement = (event.target as HTMLElement).closest(
        ".cursor-pointer"
      );
      if (targetElement) {
        cursor.classList.add("cursor-grow");
        trailingCursor.classList.add("cursor-grow");
      } else {
        cursor.classList.remove("cursor-grow");
        trailingCursor.classList.remove("cursor-grow");
      }
    };

    window.addEventListener("mousemove", updateCursor);

    return () => {
      window.removeEventListener("mousemove", updateCursor);
    };
  }, []);

  return (
    <>
      <div ref={cursorRef} className="custom-cursor"></div>
      <div ref={trailingCursorRef} className="trailing-cursor"></div>
    </>
  );
};

export default CustomCursor;
