import Contact from "../Contact";
import Hero from "../Hero";
import Projects from "../Projects";

function LandingPage() {
  return (
    <div>
      <Hero />
      <Projects />
      <Contact />
    </div>
  );
}

export default LandingPage;
