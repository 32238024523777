import { useEffect, useState } from "react";

type Theme = "light" | "dark" | "color";
type ThemeContextType = {
  theme: Theme;
  setTheme: (theme: Theme) => void;
};

export const useTheme = (): ThemeContextType => {
  const [theme, setTheme] = useState(
    (localStorage.getItem("theme") as Theme) || "color"
  );

  useEffect(() => {
    // Get the body classnames
    const bodyClassList = document.body.classList;
    let bodyTheme;
    // Check if it contains light, dark or color
    if (bodyClassList.contains("light")) {
      bodyTheme = "light";
    } else if (bodyClassList.contains("dark")) {
      bodyTheme = "dark";
    } else if (bodyClassList.contains("color")) {
      bodyTheme = "color";
    }

    setTheme(bodyTheme as Theme);
  }, [setTheme]);

  useEffect(() => {
    document.body.className = theme;
    localStorage.setItem("theme", theme);
  }, [theme]);

  return { theme: theme, setTheme: setTheme };
};
