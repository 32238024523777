// @ts-ignore
import { getCalApi } from "@calcom/embed-react";
import { useEffect } from "react";
// import { ArrowDownRightIcon } from "@heroicons/react/24/outline";

const Contact = () => {
  useEffect(() => {
    (async function () {
      const cal = await getCalApi();
      cal("ui", {
        styles: { branding: { brandColor: "#000000" } },
        hideEventTypeDetails: false,
      });
    })();
  }, []);

  return (
    <div className="mb-20 py-20  px-4 md:px-8 bg-bgPrimary" id="contact">
      <div className="h-fit flex xl:w-1/2">
        <h1 className="xl:leading-normal text-2xl md:text-5xl">
          Ready to collaborate on a new project? I am too! Drop me a line at
          <a
            href="mailto:moritz@rengert.de"
            className="underline font-semibold mx-3 cursor-pointer"
          >
            moritz@rengert.de
          </a>
          or give me a ring at{" "}
          <a
            href="tel:+49 17634512561"
            className="underline font-semibold ml-3 cursor-pointer"
          >
            +49 17634512561
          </a>
          . Alternatively, you can schedule a meeting with me right{" "}
          <button
            data-cal-link="moritzrengert/privat"
            className="underline font-semibold cursor-pointer"
          >
            here
          </button>
          .
        </h1>
      </div>
      <div className="flex gap-10 mt-10 underline cursor-pointer">
        <a href="/legal">Legal Notice</a>
        <a href="/privacy">Privacy Policy</a>
      </div>
    </div>
  );
};

export default Contact;
