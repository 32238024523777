const Footer = () => {
  // berlin time as 12h string hh:mm
  const berlinTime = new Date().toLocaleTimeString("de-DE", {
    hour12: true,
    hour: "2-digit",
    minute: "2-digit",
  });
  return (
    <footer className="bg-bgTertiary text-textPrimary py-4  px-4 md:px-8 flex  justify-between items-center h-20  fixed bottom-0 left-0 right-0  border-t border-textPrimary">
      <p className="text-sm">
        BASED IN GERMANY{" "}
        <span className="font-semibold block sm:inline">
          ({berlinTime} LOCAL TIME)
        </span>
      </p>
      <a
        href="/#contact"
        className="flex items-center space-x-2 bg-gray-200 rounded-full px-4 py-2 cursor-pointer"
      >
        <div className="animate-pulse bg-green-500 w-3 h-3 rounded-full" />
        <span className="text-gray-700 font-semibold">AVAILABLE</span>
      </a>
    </footer>
  );
};

export default Footer;
