import { ProjectLeftComponent, projectList } from "./projects/ProjectList";

const Projects = () => {
  return (
    <div className=" pb-10 pt-28 px-4 md:px-8 bg-bgQuaternary" id="portfolio">
      <h2 className="whitespace-pre-line lg:h-full font-semibold lg:font-normal mb-5 md:mb-0 text-5xl sm:leading-loose overflow-hidden">
        Some Projects{" "}
        <p className="text-xl sm:inline mt-2 sm:mt-0">
          (more on{" "}
          <a
            href="https://www.linkedin.com/in/moritz-rengert-8baaa1203/"
            className="underline cursor-pointer"
            target="_blank"
            rel="noreferrer"
          >
            linkedin
          </a>{" "}
          or{" "}
          <a
            href="https://github.com/moritzrengert/"
            className="underline cursor-pointer"
            target="_blank"
            rel="noreferrer"
          >
            github
          </a>
          )
        </p>
      </h2>
      <div>
        {projectList.map((project, idx) => (
          <ProjectLeftComponent
            key={idx}
            project={project}
            isEven={idx % 2 === 0}
          />
        ))}
      </div>
    </div>
  );
};

export default Projects;
