function LegalNotice() {
  return (
    <div className="py-28 px-4 md:px-8 text-2xl">
      <h1 className="text-5xl">Legal Notice</h1>
      <div className="mt-5">
        <h4> Information according to § 5 TMG </h4>
        <h2 className="font-semibold">
          Moritz Rengert
          <br /> Volkswohlstraße, 1 <br /> 90451 Nuremberg <br />
          <br /> Represented by: <br /> Moritz Rengert <br />
        </h2>
        <h4 className="mt-5 font-semibold"> Contact </h4>
        <p>
          Phone: +49 176-34512561 <br /> E-Mail: moritz@rengert.de
        </p>
        <h4 className="mt-5 font-semibold">EU Dispute Resolution</h4>
        <div>
          The European Commission provides a platform for online dispute
          resolution (OS):
          <a
            href="https://ec.europa.eu/consumers/odr"
            className="cursor-pointer underline block"
          >
            https://ec.europa.eu/&shy;consumers/odr.
          </a>
          You can find our email address in the imprint above.
        </div>
        <div>Consumer dispute resolution / Universal arbitration board</div>
        <div>
          We are neither willing nor obliged to participate in dispute
          resolution proceedings before a consumer arbitration board.
          <h4 className="mt-5 font-semibold">Liability for content</h4> As a
          service provider, we are responsible for our own content on these
          pages according to § 7 para. 1 TMG and general laws. According to §§ 8
          to 10 TMG, however, we are not obligated as a service provider to
          monitor transmitted or stored third-party information or to
          investigate circumstances that indicate illegal activity. <br />
          Obligations to remove or block the use of information according to
          general laws remain unaffected. However, liability in this regard is
          only possible from the point in time at which we become aware of a
          specific infringement. If we become aware of such infringements, we
          will remove this content immediately.{" "}
          <h4 className="mt-5 font-semibold"> Liability for links</h4> Our offer
          contains links to external websites of third parties, the content of
          which we have no influence over. Therefore, we cannot assume any
          liability for these external contents. The respective provider or
          operator of the sites is always responsible for the content of the
          linked sites. The linked sites were checked for possible legal
          violations at the time the link was created. No illegal content was
          discernible at the time the link was created. <br /> A permanent
          control of the content of the linked pages is not reasonable without
          concrete evidence of an infringement. As soon as we become aware of
          legal violations, we will remove such links immediately.
          <h4 className="mt-5 font-semibold">Copyright</h4> The content and
          works created by the site operators on these pages are subject to
          German copyright law. The duplication, editing, distribution and any
          kind of use outside the limits of copyright law require the written
          consent of the respective author or creator. Downloads and copies of
          this site are only permitted for private, non-commercial use. <br />{" "}
          Insofar as the content on this site was not created by the operator,
          the copyrights of third parties are respected. In particular,
          third-party content is marked as such. If you should nevertheless
          become aware of a copyright infringement , we ask for a corresponding
          notice. If we become aware of legal violations, we will remove such
          content immediately. <br />
          <br /> Source: eRecht24
        </div>
      </div>
    </div>
  );
}

export default LegalNotice;
