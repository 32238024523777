function PrivacyPolicy() {
  return (
    <div className="py-28 px-4 md:px-8 text-2xl">
      <h1 className="text-5xl">Privacy Policy</h1>
      <div className="mt-5">
        <h2 className="mt-5 font-semibold">1. Data Protection at a Glance</h2>
        <h3 className="mt-5 font-semibold">General Information</h3>
        <p>
          The following notes provide a simple overview of what happens to your
          personal data when you visit this website. Personal data is any data
          by which you can be personally identified. For more detailed
          information on data protection, please refer to our privacy policy
          listed below this text.
        </p>
        <h3 className="mt-5 font-semibold">Data Collection on this Website</h3>
        <p>
          <strong>
            Who is responsible for data collection on this website?
          </strong>
          <br />
          Data processing on this website is carried out by the website
          operator. You can find his contact details in the imprint of this
          website.
        </p>
        <p>
          <strong>How do we collect your data?</strong>
          <br />
          On the one hand, your data is collected by you providing it to us.
          This can be, for example, data that you enter in a contact form. Other
          data is collected automatically by our IT systems when you visit the
          website. This is mainly technical data (e.g. Internet browser,
          operating system or time of page view). This data is collected
          automatically as soon as you enter this website.
        </p>
        <p>
          <strong>What do we use your data for?</strong>
          <br />
          Some of the data is collected to ensure error-free provision of the
          website. Other data may be used to analyze your user behavior.
        </p>
        <p>
          <strong>What rights do you have regarding your data?</strong>
          <br />
          You have the right to receive information about the origin, recipient
          and purpose of your stored personal data free of charge at any time.
          You also have a right to request the correction or deletion of this
          data. For this purpose, as well as for further questions on the
          subject of data protection, you can contact us at any time at the
          address given in the imprint. Furthermore, you have the right to lodge
          a complaint with the competent supervisory authority.
        </p>
        <p>
          You also have the right to request the restriction of the processing
          of your personal data under certain circumstances. For details, please
          refer to the privacy policy under "Right to restriction of
          processing".
        </p>
        <h3 className="mt-5 font-semibold">
          Analysis Tools and Third-Party Tools
        </h3>
        <p>
          When visiting this website, your surfing behavior may be statistically
          analyzed. This is done primarily with so-called analysis programs.
          Detailed information on these analysis programs can be found in the
          following privacy policy.
        </p>
        <h2 className="mt-5 font-semibold">
          2. Hosting and Content Delivery Networks (CDN)
        </h2>
        <h3 className="mt-5 font-semibold">External Hosting</h3>
        <p>
          This website is hosted by an external service provider (hoster). The
          personal data collected on this website is stored on the hoster's
          servers. This may include, but is not limited to, IP addresses,
          contact requests, meta and communication data, contractual data,
          contact data, names, website accesses and other data generated via a
          website.
        </p>
        <p>
          The hoster is used for the purpose of fulfilling contracts with our
          potential and existing customers (Art. 6 para. 1 lit. b DSGVO) and
          inthe interest of a secure, fast and efficient provision of our online
          offer by a professional provider (Art. 6 para. 1 lit. f DSGVO).
        </p>
        <p>
          Our hoster will only process your data to the extent necessary to
          fulfill its service obligations and follow our instructions regarding
          this data.
        </p>
        <h2 className="mt-5 font-semibold">
          3. General Notes and Mandatory Information
        </h2>
        <h3 className="mt-5 font-semibold">Data Protection</h3>
        <p>
          The operators of these pages take the protection of your personal data
          very seriously. We treat your personal data confidentially and in
          accordance with the statutory data protection regulations and this
          data protection declaration.
        </p>
        <p>
          When you use this website, various personal data are collected.
          Personal data is data with which you can be personally identified.
          This privacy policy explains what data we collect and what we use it
          for. It also explains how and for what purpose this is done.
        </p>
        <p>
          We would like to point out that data transmission on the Internet
          (e.g. when communicating by e-mail) can have security gaps. Complete
          protection of data against access by third parties is not possible.
        </p>
        <h3 className="mt-5 font-semibold">Note on the Responsible Party</h3>
        <p>The responsible party for data processing on this website is:</p>
        <p className="font-semibold">
          Moritz Rengert
          <br />
          Volkswohlstraße 1<br />
          90451 Nuremberg
          <br />
          Telephone: +49 176-34512561
          <br />
          E-mail: moritz@rengert.de
        </p>
        <p>
          The responsible body is the natural or legal person who alone or
          jointly with others determines the purposes and means of the
          processing of personal data (e.g. names, e-mail addresses or similar).
        </p>
        <h3 className="mt-5 font-semibold">
          Revocation of Your Consent to Data Processing
        </h3>
        <p>
          Many data processing operations are only possible with your express
          consent. You can revoke consent you have already given at any time.
          For this purpose, an informal communication by e-mail to us is
          sufficient. The legality of the data processing carried out until the
          revocation remains unaffected by the revocation.
        </p>
        <h3 className="mt-5 font-semibold">
          Right to Object to Data Collection in Special Cases and to Direct
          Advertising (Art. 21 DSGVO)
        </h3>
        <p>
          IF DATA PROCESSING IS CARRIED OUT ON THE BASIS OF ART. 6 ABS. 1 LIT. E
          OR F DSGVO, YOU HAVE THE RIGHT TO OBJECT TO THE PROCESSING OF YOUR
          PERSONAL DATA AT ANY TIME FOR REASONS ARISING FROM YOUR PARTICULAR
          SITUATION; THIS ALSO APPLIES TO PROFILING BASED ON THESE PROVISIONS.
          THE RESPECTIVE LEGAL BASIS ON WHICH PROCESSING IS BASED CAN BE FOUND
          IN THIS PRIVACY POLICY. IF YOU OBJECT, WE WILL NO LONGER PROCESS YOUR
          PERSONAL DATA CONCERNED UNLESS WE CAN DEMONSTRATE COMPELLING
          LEGITIMATE GROUNDS FOR THE PROCESSING WHICH OVERRIDE YOUR INTERESTS,
          RIGHTS AND FREEDOMS, OR THE PROCESSING IS FOR THE PURPOSE OF
          ASSERTING, EXERCISING OR DEFENDING LEGAL CLAIMS (OBJECTION UNDER
          ARTICLE 21(1) DSGVO).
        </p>
        <p>
          IF YOUR PERSONAL DATA ARE PROCESSED FOR THE PURPOSE OF DIRECT
          MARKETING, YOU HAVE THE RIGHT TO OBJECT AT ANY TIME TO THE PROCESSING
          OF PERSONAL DATA CONCERNING YOU FOR THE PURPOSE OF SUCH MARKETING;
          THIS ALSO AP PLIES TO PROFILING INSOFAR AS IT IS RELATED TO SUCH
          DIRECT MARKETING. IF YOU OBJECT, YOUR PERSONAL DATA WILL SUBSEQUENTLY
          NO LONGER BE USED FOR THE PURPOSE OF DIRECT MARKETING (OBJECTION
          PURSUANT TO ARTICLE 21 (2) OF THE GERMAN DATA PROTECTION ACT).
        </p>
        <h3 className="mt-5 font-semibold">
          Right of Appeal to the Competent Supervisory Authority
        </h3>
        <p>
          In the event of violations of the GDPR, data subjects shall have the
          right to lodge a complaint with a supervisory authority, in particular
          in the Member State of their habitual residence, their place of work
          or the place of the alleged violation. The right of appeal is without
          prejudice to any other administrative or judicial remedy.
        </p>
        <h3 className="mt-5 font-semibold">Right to Data Portability</h3>
        <p>
          You have the right to have data that we process automatically on the
          basis of your consent or in performance of a contract handed over to
          you or to a third party in a common, machine-readable format. If you
          request the direct transfer of the data to another responsible party,
          this will only be done insofar as it is technically feasible.
        </p>
        <h3 className="mt-5 font-semibold">SSL or TLS Encryption</h3>
        <p>
          For security reasons and to protect the transmission of confidential
          content, such as orders or requests that you send to us as the site
          operator, this site uses SSL or TLS encryption. You can recognize an
          encrypted connection by the fact that the address line of the browser
          changes from "http://" to "https://" and by the lock symbol in your
          browser line.
        </p>
        <p>
          If SSL or TLS encryption is activated, the data you transmit to us
          cannot be read by third parties.
        </p>
        <h3 className="mt-5 font-semibold">
          Information, Deletion and Correction
        </h3>
        <p>
          Within the framework of the applicable legal provisions, you have the
          right at any time to free information about your stored personal data,
          its origin and recipient and the purpose of data processing and, if
          necessary, a right to correction or deletion of this data. For this
          purpose, as well as for further questions on the subject of personal
          data, you can contact us at any time at the address given in the
          imprint.
        </p>
        <h3 className="mt-5 font-semibold">
          Right to Restriction of Processing
        </h3>
        <p>
          You have the right to request the restriction of the processing of
          your personal data. To do this, you can contact us at any time at the
          address given in the imprint. The right to restriction of processing
          exists in the following cases:
        </p>
        <ul>
          <li>
            If you dispute the accuracy of your personal data stored by us, we
            usually need time to verify this. For the duration of the
            verification, you have the right to request the restriction of the
            processing of your personal data.
          </li>
          <li>
            If the processing of your personal data happened/is happening
            unlawfully, you can request the restriction of data processing
            instead of deletion.
          </li>
          <li>
            If we no longer need your personal data, but you need it to
            exercise, defend or assert legal claims, you have the right to
            request restriction of the processing of your personal data instead
            of erasure.
          </li>
          <li>
            If you have lodged an objection pursuant to Art. 21 (1) DSGVO, a
            balancing of your and our interests must be carried out. As long as
            it has not yet been determined whose interests prevail, you have the
            right to request the restriction of the processing of your personal
            data.
          </li>
        </ul>
        <p>
          If you have restricted the processing of your personal data, this data
          - apart from its storage - may only be processed with your consent or
          for the assertion, exercise or defense of legal claims or for the
          protection of the rights of another natural or legal person or for
          reasons of an important public interest of the European Union or a
          Member State.
        </p>

        <h2 className="mt-5 font-semibold">
          4. Data Collection on this Website
        </h2>
        <h3 className="mt-5 font-semibold">Server Log Files</h3>
        <p>
          The provider of the pages automatically collects and stores
          information in so-called server log files, which your browser
          automatically transmits to us. These are:
        </p>
        <ul>
          <li>Browser type and browser version</li>
          <li>Operating system used</li>
          <li>Referrer URL</li>
          <li>Host name of the accessing computer</li>
          <li>Time of the server request</li>
          <li>IP address</li>
        </ul>
        <p>A combination of this data with other data sources is not made.</p>
        <p>
          The collection of this data is based on Art. 6 para. 1 lit. f DSGVO.
          The website operator has a legitimate interest in the technically
          error-free presentation and optimization of its website - for this
          purpose, the server log files must be collected.
        </p>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
