import ThemeSwitcher from "./ThemeSwitcher";

const Header = () => {
  return (
    <header className="bg-bgSecondary text-textPrimary py-4 px-4 md:px-8 flex items-center justify-between h-20 fixed top-0 left-0 right-0 border-b border-textPrimary z-50">
      <a href="/#portfolio" className="w-15 underline cursor-pointer">
        <h3>PORTFOLIO</h3>
      </a>
      <ThemeSwitcher />
      <a href="/#contact" className="w-15 underline cursor-pointer">
        <h3>CONTACT</h3>
      </a>
    </header>
  );
};

export default Header;
