import { useTheme } from "../ThemeProvider";
import { MoonIcon, SunIcon, SparklesIcon } from "@heroicons/react/24/outline";

const ThemeSwitcher = () => {
  const { theme, setTheme } = useTheme();
  return (
    <div className="flex items-center justify-end cursor-pointer">
      {theme === "light" ? (
        <SparklesIcon className="h-7 w-7" onClick={() => setTheme("color")} />
      ) : theme === "dark" ? (
        <SunIcon className="h-7 w-7" onClick={() => setTheme("light")} />
      ) : theme === "color" ? (
        <MoonIcon className="h-7 w-7" onClick={() => setTheme("dark")} />
      ) : (
        <p>Error</p>
      )}
    </div>
  );
};

export default ThemeSwitcher;
