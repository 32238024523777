import { useMemo, useState } from "react";
import SlidingBanner from "./SlidingBanner";
import { ArrowUpRightIcon } from "@heroicons/react/24/outline";

type Project = {
  name: string;
  description: string;
  image: string;
  url: string;
  client?: {
    name: string;
    url: string;
  };
};

export const projectList: Project[] = [
  {
    name: "Gut&Bösel",
    description:
      "Webdevelopement for Gut&Bösel which represent the fusion of tradition and innovation in Alt Madlitz, combining hands-on agriculture with advanced research in forestry.",
    image: require("../../assets/g&b.png"),
    url: "https://gutundboesel.org",
    client: {
      name: "Paaie",
      url: "https://paaie.io",
    },
  },
  {
    name: "Audioeffekt",
    description:
      "Webdevelopment for Audioeffekt which is spezializing in researching the effectiveness of radio spots and audio campaigns.",
    image: require("../../assets/audioeffekt.png"),
    url: "https://audioeffekt.de",
    client: {
      name: "Paaie",
      url: "https://paaie.io",
    },
  },
  {
    name: "Knugget",
    description:
      "Web-, Crossplatform App-, Backend- and Database-Development for Knugget, the platform for finding, sharing and retaining knowledge. Mostly with the help of Typescript.",
    image: require("../../assets/knugget.jpeg"),
    url: "https://letsknugget.com",
  },
  {
    name: "Formbricks",
    description:
      "NextJs and React based Webdevelopment for Formbricks, the open source form and survey tool for B2B businesses.",
    image: require("../../assets/formbricks.png"),
    url: "https://formbricks.com/",
  },
];

export function ProjectLeftComponent({
  project,
  isEven,
}: {
  project: Project;
  isEven: boolean;
}) {
  const [isActive, setIsActive] = useState(false);
  const isMobile = useMemo(() => window.innerWidth < 768, []);

  return (
    <div
      className={`md:flex py-10 md:py-0 border-y border-textPrimary ${
        isEven ? "flex-row-reverse" : ""
      }`}
      onMouseOver={() => setIsActive(true)}
      onMouseLeave={() => setIsActive(false)}
    >
      <div className="min-w-[250px] 2xl:min-w-[400px] md:p-4">
        <a href={project.url} target="_blank" rel="noreferrer">
          <h2 className="italic text-2xl font-semibold cursor-pointer">
            {project.name}
            <ArrowUpRightIcon className="w-4 inline ml-2 mb-1" />
          </h2>
        </a>
        <p>{project.description}</p>
        {project.client && (
          <a
            href={project.client.url}
            target="_blank"
            rel="noreferrer"
            className="cursor-pointer font-semibold"
          >
            for @{project.client?.name.toLowerCase()}
          </a>
        )}
      </div>
      <div className="py-4 md:px-8 md:border-x border-textPrimary w-full md:min-w-fit cursor-pointer">
        <a href={project.url} target="_blank" rel="noreferrer">
          <img
            src={project.image}
            alt={project.name}
            className="w-full md:max-w-[300px] md:min-h-[300px] max-h-[200px] md:max-h-[350px] object-cover rounded-lg md:rounded-none"
          />
        </a>
      </div>
      <div className="md:p-4 flex items-center">
        <SlidingBanner text={project.name} active={isActive || isMobile} />
      </div>
    </div>
  );
}
